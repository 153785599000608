import React from "react";
import "./map.scss";
import { BsFillTelephoneFill } from "react-icons/bs";
import { TfiEmail } from "react-icons/tfi";
import { TiLocation } from "react-icons/ti";
import GoogleMap from "./GoogleMap";
const Map = () => {
  return (
    <div className="map-container">
      <h4>KONTAKTINFORMATIONEN</h4>

      <div className="map-content">
        <div className="map-info">
          <div className="map-span map-num">
            <span className="icon-info tel">
              <BsFillTelephoneFill />
            </span>
            <h4 className="infos">
              Ansprechpartner: Riza Lushaj
              <br />
              +49 163 1375938
            </h4>
          </div>
          <div className="map-span">
            {" "}
            <span className="icon-info">
              <TfiEmail />{" "}
            </span>{" "}
            <h4 className="infos">info@elektro-lu.eu</h4>
          </div>
          <div className="map-span">
            {" "}
            <span className="icon-info">
              {" "}
              <TiLocation />{" "}
            </span>
            <div>
              <h4 className="infos address">
                Schwimmbadstraße 3, <br />
                Durmersheim 76448
              </h4>
            </div>
          </div>
        </div>
        <div className="map">
          <GoogleMap />
        </div>
      </div>
    </div>
  );
};

export default Map;
