import "./header.scss";

import BackgroundSlider from "../../components/background/BackgroundSlider";

const Header = () => {
  // const particlesInit = useCallback(async (engine) => {
  //   console.log(engine);

  //   await loadFull(engine);
  // }, []);

  // const particlesLoaded = useCallback(async (container) => {
  //   await console.log(container);
  // }, []);

  return (
    <div name='home' >
      
<BackgroundSlider/>    
    </div>
   
    //   <div className="info-container">
    //     <div className="info-content">
    //       <span className="infos">+49 163 1375938</span>
    //       <span className="infos">info@elektro-lu.eu</span>
    //       <span className="infos">
    //         Schwimmbadstraße 3,<span> Durmersheim 76448</span>
    //       </span>
    //     </div>
    //     <div className="info-icons">
    //       <span className="icons">
    //         {" "}
    //         <Link to="https://www.instagram.com/elektro.lu/">
    //           <FaFacebookF />
    //         </Link>
    //       </span>

    //       <span className="icons">
    //         {" "}
    //         <Link to="https://www.instagram.com/elektro.lu/">
    //           <SiInstagram />
    //         </Link>
    //       </span>
    //       <span className="icons">
    //         {" "}
    //         <Link to="/kontakt">
    //           <FiMail />
    //         </Link>
    //       </span>
    //     </div>
    //   </div> */}
 
    //   </div>
    // </div>
  );
};
export default Header;
