import React from 'react'
import {MdOutlineElectricalServices,MdOutlineTipsAndUpdates} from 'react-icons/md'
import {FaSearchengin,FaSolarPanel} from 'react-icons/fa'
import {RiAlarmWarningLine} from 'react-icons/ri'
import {AiOutlineControl} from 'react-icons/ai'
import smarthome from '../../assets/smarthome.png'
import wallbox from '../../assets/wallbox.png'
import messung from '../../assets/messung.png'
import telekom from '../../assets/telekom.png'
import elektromessung from '../../assets/elektromessung.png'
import brandalarm from '../../assets/brandalarm.png'
import plan from '../../assets/plan.png'
import dve from '../../assets/dve.png'
import dguv from '../../assets/dguv.png'
import highvoltage from '../../assets/24h.png'
import sprechanlage from '../../assets/sprachanlage.png'
import led from '../../assets/led.png'
const ServicesList = () => {
  return (
    <div className='serv-list'>
       
        <div className="serv-item">
            <div className="serv-icon"><MdOutlineElectricalServices/></div>
            <div className="serv-text">Elektroinstallationen</div>
        </div>
        <div className="serv-item">
            <div className="serv-icon"><FaSearchengin/></div>
            <div className="serv-text">Fehlersuchen</div>
        </div>
        <div className="serv-item">
            <div className="serv-icon"><img alt='led licht beleuchtungstechnik beleuchtung 'src={led}/></div>
            <div className="serv-text">LED / Beleuchtungstechnik</div>
        </div>
       
        <div className="serv-item">
            <div className="serv-icon"><img alt='wallbox elektrikauto hybrid autoElektroauto-Ladestation
Ladestation für Elektrofahrzeuge
Ladepunkt für Elektromobilität
Ladeinfrastruktur für E-Autos
E-Mobilität Ladegerät
Schnellladestation für Elektroautos
Wallbox-Installation
Elektromobilität Aufladung
E-Auto-Ladebox
Elektrofahrzeug-Ladegerät'src={wallbox}/></div>
            <div className="serv-text">Wallbox/Ladesäulen</div>
        </div>
        <div className="serv-item">
            <div className="serv-icon"><FaSolarPanel/></div>
            <div className="serv-text">Photovoltaikanlagen/Solar</div>
        </div>
        <div className="serv-item">
            <div className="serv-icon"><img alt='smarthome Hausautomation
Intelligente Haustechnik
Smarte Haussteuerung
Vernetztes Zuhause
Home-Automation
Smart-Home-Systeme
Internet of Things (IoT) im Wohnbereich
Hausautomatisierungstechnologien
Smarte Wohnlösungen
Intelligente Hausgeräte ' src={smarthome}/></div>
            <div className="serv-text">Smart Home</div>
        </div>
        <div className="serv-item">
            <div className="serv-icon"><img alt='high voltage strom stromer 'src={highvoltage}/></div>
            <div className="serv-text">Notdienst</div>
        </div>
        <div className="serv-item">
            <div className="serv-icon"><MdOutlineTipsAndUpdates/></div>
            <div className="serv-text">Fachliche Beratungen</div>
        </div>
        <div className="serv-item">
            <div className="serv-icon"><img alt='Präzise Messungen
Messinstrumente
Messdatenanalyse
Messverfahren
Messgeräte
Messgenauigkeit
Messergebnisse
Messtechnik
Messmethoden
Datenmessung' src={messung}/></div>
            <div className="serv-text">Installationsmessungen </div>
        </div>
        <div className="serv-item">
            <div className="serv-icon"><img alt='Elektrische Messgeräte
Elektromessgeräte
Messung elektrischer Parameter
Elektrische Prüfung
Elektrische Messverfahren
Messung von Stromstärke und Spannung
Elektrotechnische Messungen
Elektroprüfung
Elektrische Messdatenanalyse
Hochpräzise Elektromessungen' src={elektromessung}/></div>
            <div className="serv-text">Zähleranlagen</div>
        </div>
        <div className="serv-item">
            <div className="serv-icon"><RiAlarmWarningLine/></div>
            <div className="serv-text">Alarmanlagen</div>
        </div>
        <div className="serv-item">
            <div className="serv-icon"><img alt='Kommunikationstechnologie
Telekommunikationssysteme
Telekommunikationslösungen
Netzwerktechnik
Breitbandkommunikation
Datenübertragungstechnik
Mobilfunktechnologie
Glasfasertechnik
Satellitenkommunikation
VoIP (Voice over IP)' src={telekom}/></div>
            <div className="serv-text">Telekommunikationstechnik</div>
        </div>
        <div className="serv-item">
            <div className="serv-icon"><img src={sprechanlage} alt='sprechanlage klingel'/></div>
            <div className="serv-text">Sprechanlage / Klingel</div>
        </div>  
        <div className="serv-item">
            <div className="serv-icon"><img src={brandalarm} alt='brandalarm alarm'/></div>
            <div className="serv-text">Brandmeldeanlagen</div>
        </div>   <div className="serv-item">
            <div className="serv-icon"><AiOutlineControl/></div>
            <div className="serv-text">Steuerungen</div>
        </div>   <div className="serv-item">
            <div className="serv-icon"><img src={plan} alt=' plan plannungen'/></div>
            <div className="serv-text">Elektroplanungen</div>
        </div>   
        <div className="serv-item ">
            <div className="serv-icon"><img src={dve} alt='installation messung din vde 0100'/></div>
            <div className="serv-text  txt-center">Installationsmessung nach DIN VDE 0100 Teil 600</div>
        </div>   
         <div className="serv-item ">
            <div className="serv-icon"><img src={dguv} alt='geräteprüfung dguv din vde'/></div>
            <div className="serv-text txt-center">Geräteprüfung nach DGUV V-3 und DIN VDE 0701/702</div>
        </div>   

        </div>   


    
  )
}


export default ServicesList