import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Policy
 from '../sections/policy/Policy'
import Footer from '../sections/footer/Footer'
import './termsofpolicy.scss'
const TermsOfPolicy = () => {
  return (
    <div className='termsofpolicy'>
       <div className='policy-navbar'><Navbar/></div> 

        <Policy />
        <Footer/>

    </div>
  )
}

export default TermsOfPolicy