import React from 'react'
import './impressum.scss'
const Impressum = () => {
  return (
    <div className='impressum'><h1>Impressum</h1>

    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <p>Riza Lushaj<br />
    Elektro LU - Meisterbetrieb<br />
    Schwimmbadstra&szlig;e  3<br />
    76448 Durmersheim</p>
    
    <h2>Kontakt</h2>
    <p>Telefon: +491631375938<br />
    E-Mail: info@elektro-lu.eu</p>

    <p>Berufsbezeichnung:<br />
    Meisterelektriker</p>
   
   <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2> 
   <p>Riza Lushaj<br />
    Elektro LU - Meisterbetrieb <br />
    Schwimmbadstra&szlig;e  3<br />
    76448 Durmersheim</p>
    <h2>Haftungsausschluss:</h2>
<h2>Haftung für Inhalte</h2>
<p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
</p>
<h2>Haftung für Links</h2>
<p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
  </p>
   <h2>Urheberrecht</h2> 
<p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
</p>

<h2>Datenschutz</h2>
<p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Weitere Informationen zum Datenschutz finden Sie in unserer<a href='/datenschutzerklärung'> Datenschutzerklärung</a>.
    
    </p></div>
  )
}

export default Impressum