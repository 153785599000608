import "./navbar.scss";
import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose} from "react-icons/ai";
import elec from '../../assets/electric-logo.png';
import names from "../../assets/emri.png"
import {MdHomeFilled} from 'react-icons/md'
import {HashLink} from 'react-router-hash-link'
function Navbar() {

const [nav,setNav]=useState(false)
const handleNav=()=>setNav(!nav)


  return (
  
    <div className="navbar">

<div className="logos">
        <img className="img1" alt="elektro lu elektrik logo" src={elec}/>
        <img className="names" alt='deutsche elektriker elektro elektro-lu'src={names}/>
    </div>
      <ul className="nav-menu links">
        <li>
         <a href='/'smooth={true} duration={800}> <MdHomeFilled color="white" size={32}/></a>
        </li>
        <HashLink to='/#überuns'smooth duration={1000} > <li>Über uns</li></HashLink>
        <HashLink to='/#service'smooth={true} duration={800}> <li>Services</li></HashLink>
        <HashLink to='/#kontakt'smooth duration={1000}><li>Kontakt</li> </HashLink>   </ul>
      <div className="nav-icons" style={{marginRight:'1rem'}}>
      
      </div>
      <div onClick={handleNav} className="hamburger">
 {!nav ?(<AiOutlineMenu className='icon'style={{ height: 36, width: 53 }} />) : (<AiOutlineClose className="icon"/>)} 
      </div>


      <div className={nav ?'mobile-menu active':'mobile-menu'}>
        <ul className='mobile-nav links'>
          <li >
          <a href="/"> <MdHomeFilled /></a>
        </li>
        <li><HashLink to='/#überuns'smooth duration={1000} > Über uns</HashLink></li>
      <li>  <HashLink to='/#service'smooth={true} duration={800}> Services</HashLink></li>
       <li> <HashLink to='/#kontakt'smooth duration={1000}>Kontakt </HashLink> </li>  
    </ul>
       
      </div>
    </div>
  );
}

export default Navbar;
