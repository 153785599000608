import Contact from "./sections/contact/Contact";
import Aboutus from "./sections/aboutus/Aboutus";
import Footer from "./sections/footer/Footer";
import Header from "./sections/header/Header";
import { BrowserRouter as Router, Route, Switch, Routes } from "react-router-dom";
import Services from "./sections/services/Services";
import Mainpage from "./page/Mainpage";
import TermsOfPolicy from "./page/TermsOfPolicy";
import ImpressumPage from "./page/ImpressumPage";


const App = () => {
    
    return (
    
      <div className="app">    <Routes>
        <Route exact path="/" element={<Mainpage />}/>
         
          <Route path="datenschutzerklärung" element={<TermsOfPolicy />} />
        
          <Route path="impressum" element={<ImpressumPage />} />
        
      </Routes>
    </div>  
        
       
    );
};
export default App