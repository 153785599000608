import React from 'react'
import './aboutus.scss'
import logo from '../../assets/electric-logo.png'
import AbtusBtn from '../../components/abtusBtn/AbtusBtn'
const Aboutus = () => {
  return (
    <div className='aboutus' name='überuns' id='überuns'>
        <div className='abt-container'>
        <h3>ÜBER UNS</h3>
          <h1 className='abt-title'>WILLKOMMEN</h1>

        </div>
        
  
        <div className='abt-bg'>
            <div className='abt-bg-cl'>
        <div className='abt-logo'>
           <img src={logo} alt='elektro lu logos'/>
        </div>
        <div className='abt-content'>
            <p>Unser Unternehmen bietet eine umfassende Palette von elektrischen Dienstleistungen für private, gewerbliche und industrie Kunden. Wir sind stolz auf unsere außergewöhnliche Verarbeitung, zuverlässigen Service und wettbewerbsfähige Preise.
           </p><p>Egal, ob Sie elektrische Dienstleistungen für Ihr Zuhause oder Ihr Unternehmen benötigen, wir bieten zuverlässige und professionelle Lösungen, die auf Ihre spezifischen Bedürfnisse zugeschnitten sind.
    
     </p>

     <AbtusBtn/>
        </div></div></div></div>
  
  )
}

export default Aboutus            