import React from "react";

import "./infobox.scss";
const Infobox = () => {
  const handlePhoneNumberClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <div className="main">
      <div className="info-container">
        <div className="info-content">
          <div className="infos num">
            <span>Ansprechspartner:Riza Lushaj</span>
            <span
              className="number"
              onClick={() => handlePhoneNumberClick("+49 163 1375938")}
            >
              +49 163 1375938
            </span>
          </div>
          <span className="infos">info@elektro-lu.eu</span>
          <div className="address infos">
            <span className="">Schwimmbadstraße 3,</span>
            <span> Durmersheim 76448</span>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default Infobox;
