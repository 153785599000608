import React from 'react'
import Header from '../sections/header/Header'
import Contact from '../sections/contact/Contact'
import Footer from '../sections/footer/Footer'

import Aboutus from '../sections/aboutus/Aboutus'
import Services from '../sections/services/Services'


const Mainpage = () => {
  return (
    <>  <Header/>
  <Aboutus/>
  {/* <Ourteam/> */}
  <Services/>
<Contact/>

<Footer/>
    </>
  )
}

export default Mainpage