const imageSlide = [
    //{ alt: 'elektriker durmersheim', url: 'https://res.cloudinary.com/dzxdzqayq/image/upload/v1681137353/electrical-fault-finding_pjo62z.jpg' },
    { alt: 'solar photovoltaikanlage durmersheim karlsruhe würmersheim', url: 'https://res.cloudinary.com/dzxdzqayq/image/upload/v1681137747/background_nmbnyo.jpg' },
    { alt: 'lampen smart home installationen rheinstetten fehlersuche ', url: 'https://res.cloudinary.com/dzxdzqayq/image/upload/v1681137699/r-architecture-wot0Q5sg91E-unsplash_bmydrp.jpg' },
    { alt: 'muggensturm installationsmessungen', url: 'https://res.cloudinary.com/dzxdzqayq/image/upload/v1683904531/vula_jgdvzb.jpg' },
    { alt: 'fahrer bus notdienst', url: 'https://res.cloudinary.com/dzxdzqayq/image/upload/v1685381720/kombi_nlkock.jpg' },
    { alt: 'elektriker zähler',url:'https://res.cloudinary.com/dzxdzqayq/image/upload/v1704742008/sahati_el4kou.jpg'},
{alt: 'tiefgarage garage led lichtung außer licht',url:'https://res.cloudinary.com/dzxdzqayq/image/upload/v1704743701/garazhda_r3hebg.jpg'},
{alt:'büro dach lichtung panel-led licht',url:'https://res.cloudinary.com/dzxdzqayq/image/upload/v1704743113/zyra_kzalzd.jpg'},
//{alt:'vollbox kiste',url:'https://res.cloudinary.com/dzxdzqayq/image/upload/v1704743120/kutia_lxlwvb.jpg'}

];
export default imageSlide