import React from 'react';
import { Link } from 'react-scroll';
import "./glButton.scss";

const GlButton = () => {
  return (
    <Link to='kontakt' smooth={true} duration={800}>
      <button className='glowing-btn'>
        <span className='glowing-txt'>Erreichen Sie uns</span>
      </button>
    </Link>
  );
};

export default GlButton;
