import React from 'react'
import './footer.scss'
import elec from '../../assets/electric-logo.png';
import names from "../../assets/emri.png"
import {Link} from 'react-scroll'
import { SiInstagram } from "react-icons/si";
import {FiMail} from "react-icons/fi"
import Minifooter from './Minifooter';
import {HashLink} from 'react-router-hash-link'

const Footer = () => {
  return (
    <div className='footer'>
      <div  className='footer-container'>
        <div className="footer-logo"> <img className="img1" alt="elektro lu elektrik logo"src={elec}/>
        <img className="names" alt='deutsche elektriker elektro elektro-lu' src={names}/>
        </div>
      
        <div className="footer-menu">
        <ul className="nav-menu links">
        
        <li>
         <a href='/'smooth={true} duration={800}> Start</a>
        </li>
        <HashLink to='/#überuns'smooth duration={1000} > <li>Über uns</li></HashLink>
        <HashLink to='/#service'smooth={true} duration={800}> <li>Services</li></HashLink>
        <HashLink to='/#kontakt'smooth duration={1000}><li>Kontakt</li> </HashLink>   </ul>
         </div>  <div className="footer-icons">
            
           
         

     <span className="iconss">
             {" "}
             <a href="https://www.instagram.com/elektro.lu/">
               <SiInstagram />
             </a>
           </span>
           <span className="iconss">
             {" "}
             <Link to="kontakt">
               <FiMail />
             </Link>
           </span>
     
       
       </div> 
       </div>
       <div>
       
        <Minifooter/> </div> </div>
  )
}

export default Footer