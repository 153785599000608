import React from 'react'
import './services.scss'
import ServicesList from './ServicesList'
const Services = () => {
  return (
    <div className='services' name='service' id='service'>
        <div className=''>   <div className='ser-container'>
        <h3>SERVICES</h3>
          <h1 className='ser-title'>INDUSTRIE-GEWERBE-PRIVAT</h1>

        </div><hr className='ser-line'/>
        </div>
        <ServicesList/>
        <hr className='ser-line'/>
    </div>
  )
}

export default Services
