import React from 'react'
import { Link } from 'react-router-dom';
const Minifooter = () => {
  return (
    <div className='mini-footer'><p>© 2023 Elektro LU</p>  

    <ul className='foot-list'>
    {/* <li><Link to='/nutzungsbedingungen'> Nutzungsbedingungen</Link></li> */}
         <li><Link to='/datenschutzerklärung'> Datenschutzerklärung</Link></li>
    <li><Link to='/impressum'> Impressum</Link></li></ul>
    </div>
  )
}

export default Minifooter