import React from 'react'
import "./contact.scss"
import Email from '../../components/email/Email'
import Map from '../../components/map/Map'
const Contact = () => {
  return (
    <div className='contact' name='kontakt' id='kontakt'>
        <div className='abt-txt-container'>
          <h3>KONTAKT</h3>
          <h1 className='abt-title'>ERREICHEN SIE UNS</h1>
          {/* <p>*Beschreiben Sie uns Ihr Problem, wir melden uns gerne bei Ihnen zurück und bieten Ihnen die bestmögliche Lösung.</p> */}
          </div>
          <div className="parts">
          <div className='email-part part'><Email/></div>
          <div className='map-part part'><Map/></div></div>
    </div>
  )
}

export default Contact




