import React, { useState, useEffect } from "react";
import "./backgroundslider.scss";
import imageSlide from "../../data";
import Navbar from "../navbar/Navbar";
import Infobox from "../infobox/Infobox";
import { SiInstagram } from "react-icons/si";
import { FiMail, FiPhone } from "react-icons/fi"; 
import { Link } from "react-scroll";
import GlButton from "../glowingBtn/GlButton";
const BackgroundSlider = () => {
  const [index, setIndex] = useState(0);
  const handlePhoneNumberClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const delay = 4000;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIndex((index + 1) % imageSlide.length);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [index]);
  // const [showPopup, setShowPopup] = useState(false);

  // useEffect(() => {
  //   // Show the popup when the component mounts
  //   setShowPopup(true);
  // }, []);

  // const handleClosePopup = () => {
  //   setShowPopup(false);
  // };
  return (
    <div>
      <div className="overlay"></div>
      <div className="slideshow">
        <Navbar />

        <div className="slideshow-content">
          <h1 className="title">Erlebe die Professionalität</h1>
          <h2 className="subtitle">Meisterbetrieb</h2>
          <GlButton />
          {/* {showPopup && <Popup message="We are going on vacation!" onClose={handleClosePopup} />}
     */}
        </div>
        <div className="infos-txt">
          <div className="info-icons">
            <span className="icons">
              {" "}
              <a href="https://www.instagram.com/elektro.lu/">
                <SiInstagram />
              </a>
            </span>

            <span className="icons">
              {" "}
              <Link to="kontakt" smooth={true} duration={800}>
                <FiMail />
              </Link>
            </span>
            <span
              className="icons number"
              onClick={() => handlePhoneNumberClick("+49 163 1375938")}
            >
              {" "}
              <FiPhone />
            </span>
          </div>
          <div className="infobox">
            <Infobox />
          </div>
        </div>
        <div className="triangle"></div>

        <div className="slideshowSlider ">
          {imageSlide.map((image, idx) => (
            <div
              className={`slide ${idx === index ? "active" : ""}`}
              style={{ backgroundImage: `url(${image.url})` }}
              key={idx}
            ></div>
          ))}
        </div>
      </div>{" "}
    </div>
  );
};

export default BackgroundSlider;
