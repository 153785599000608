import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Impressum from '../sections/policy/Impressum'
import Footer from '../sections/footer/Footer'
import '../sections/policy/impressum.scss'

const ImpressumPage = () => {
  return (
    <div className='main-impressum'>
    <div className='policy-navbar'><Navbar/></div> 

<Impressum/>
<Footer/>

    </div>
  )
}

export default ImpressumPage