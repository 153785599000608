import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './email.scss';

const Email = () => {
  const form = useRef();
  const [inputs, setInputs] = useState({
    user_name: '',
    user_email: '',
    user_telephone: '',
    message: ''
  });

  const [focusedInputs, setFocusedInputs] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    // Check validity before attempting to send email
    if (!isFormValid()) {
      setFocusedInputs({
        user_name: true,
        user_email: true,
        user_telephone: true,
        message: true
      });
      return;
    }

    setIsWaiting(true); // Show "Bitte warten!" message

    emailjs.sendForm('service_p3ynide', 'template_2muybgd', form.current, 'cOOmGnm-QnHY5X2Xk')
      .then((result) => {
        console.log(result.text);
        const responseOutput = form.current.querySelector('.response-output');
        responseOutput.textContent = 'E-Mail wurde gesendet!';
        responseOutput.classList.add('success');
        setIsWaiting(false); // Hide "Bitte warten!" message
      }, (error) => {
        console.log(error.text);
        const responseOutput = form.current.querySelector('.response-output');
        responseOutput.textContent = 'E-Mail wurde nicht gesendet. Bitte erneut versuchen!';
        responseOutput.classList.add('error');
        setIsWaiting(false); // Hide "Bitte warten!" message
      });
  };

  const handleInputFocus = (e) => {
    const { name } = e.target;
    setFocusedInputs((prevFocusedInputs) => ({ ...prevFocusedInputs, [name]: true }));
  };

  const handleCheckedBox = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const handleClick = () => {
    setButtonClicked(true);
  };

  const isFormValid = () => {
    return (
      inputs.user_name.trim() !== '' && // Name must be filled
      inputs.message.trim() !== '' && // Beschreibung must be filled
      (inputs.user_email.trim() !== '' || inputs.user_telephone.trim() !== '') && // Either Email or Telephone must be filled
      isChecked // Checkbox for privacy policy must be checked
    );
  };

  return (
    <div className="emails-container">
      <form ref={form} onSubmit={sendEmail}>
        <h1 className="title-contact">SENDEN SIE UNS EINE NACHRICHT</h1>
        
        <label>NAME</label>
        <input
          onFocus={handleInputFocus}
          className="abt-input"
          type="text"
          name="user_name"
          value={inputs.user_name}
          onChange={handleInputChange}
          required
        />
        {focusedInputs.user_name && inputs.user_name.trim() === '' && (
          <span className="not-valid-tip show">Das Feld ist erforderlich.</span>
        )}

        <label>EMAIL</label>
        <input
          onFocus={handleInputFocus}
          className="abt-input"
          type="email"
          name="user_email"
          value={inputs.user_email}
          onChange={handleInputChange}
        />
        <label>TELEFONNUMMER</label>
        <input
          onFocus={handleInputFocus}
          className="abt-input"
          type="text"
          name="user_telephone"
          value={inputs.user_telephone}
          onChange={handleInputChange}
        />
        {(focusedInputs.user_email || focusedInputs.user_telephone) &&
          inputs.user_email.trim() === '' &&
          inputs.user_telephone.trim() === '' && (
            <span className="not-valid-tip show">
              Bitte schreiben Sie Ihre E-Mail oder Ihre Telefonnummer.
            </span>
          )}

        <label className='title-txt'>BESCHREIBUNG</label>
        <input
          className="abt-input txt-box"
          name="message"
          value={inputs.message}
          onChange={handleInputChange}
          required
        />
        {focusedInputs.message && inputs.message.trim() === '' && (
          <span className="not-valid-tip show">Das Feld ist erforderlich.</span>
        )}

        <div className='check-box'>
          <input
            className='checkbox'
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckedBox}
          />
          <p>
            Ich habe die <a href='/datenschutzerklärung'>Datenschutzerklärung</a> gelesen und stimme sie zu.
          </p>
        </div>
        {!isChecked && buttonClicked && (
          <span className="not-valid-tip show">Bitte akzeptieren Sie die Datenschutzerklärung.</span>
        )}
        
        <input
          className="send-btn"
          type="submit"
          value="Absenden"
          onClick={handleClick}
        />
        {buttonClicked && !isFormValid() && (
          <span className="not-valid-tip show">Bitte füllen Sie die erforderlichen Felder aus.</span>
        )}
        {buttonClicked && isWaiting && (
          <span className="not-valid-tip wait show">
            <div className="wait-icon"></div>
            Bitte warten!
          </span>
        )}
        <div className="response-output" aria-hidden="true"></div>
      </form>
    </div>
  );
};

export default Email;
